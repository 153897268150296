import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Home from "./pages/Home";
import "./assets/css/style.css";
import HumanRights from "./pages/HumanRights";
import ContactUS from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from "./pages/PageNotFound";
import AboutUs from "./pages/AboutUs";
import CookiePolicy from "./pages/CookiePolicy";
import TermsOfService from "./pages/TermsOfService";
import Faq from "./pages/Faq";
import { initGA4 } from "./gtag";
import RenewableEnergy from "./pages/RenewableEnergy";
import SupplyChain from "./pages/SupplyChain";

const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
initGA4(TRACKING_ID);

function App() {
  useEffect(() => {
    const gaScript = document.createElement("script");
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`;
    gaScript.async = true;
    document.head.appendChild(gaScript);
    const gaScript2 = document.createElement("script");
    gaScript2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.REACT_APP_GA_MEASUREMENT_ID}');
    `;
    document.head.appendChild(gaScript2);
  });
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/HumanRights" element={<HumanRights />} />
          <Route path="/SupplyChain" element={<SupplyChain />} />
          <Route path="/RenewableEnergy" element={<RenewableEnergy />} />
          <Route path="/ContactUs" element={<ContactUS />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
