import React, { useEffect } from "react";
import { waitForGtag } from "../utils/ga-utils";

const PrivacyPolicy = () => {
  useEffect(() => {
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
  });
  return (
    <div className="policy">
      <div className="container">
        <div className="policy-heading">
          <h2>Privacy Policy</h2>
          {/* <p>Last Updated: 14.05.2024</p> */}
        </div>
        <div className="policy-content">
          <p>
            This Privacy Policy, as amended or otherwise changed from time to
            time (the "Privacy Policy" or "Policy"), explains the manner in
            which ENVIRONMENTAL SOCIAL GOVERNANCE GATE SOCIEDAD LIMITADA.,
            registry code B13934112, SPAIN GETXO CARRETERA ZIENTOETE 11
            (hereinafter "ESGATE") maintains and discloses user information
            obtained through its website https://www.esgate.es (the "Site"). The
            terms "we," "us," and "our" refer to the Company. By using the Site,
            you ("User") consent to the data practices prescribed in this
            Privacy Policy.
          </p>
          <p>
            <br />
            Your Personal Data is processed under this Privacy Policy and in
            accordance with applicable legislation, including the General Data
            Protection Regulation (2016/679) ("GDPR") and the applicable
            national data protection laws ("Data Protection Law"). The Company
            acts as data controllers of your Personal Data.
          </p>
          <p>
            <br />
            On occasion, the Company may revise this Privacy Policy to reflect
            changes in the law, our personal data collection and use practices,
            the features on the Site, or advances in technology. If material
            changes are made to this Privacy Policy, the changes will be
            prominently posted on the Site.
          </p>

          <h3>1. What if I do not want to accept this Privacy Policy?</h3>

          <p>
            By using this Site, you signify your acceptance of this Policy. If
            you do not agree to this Policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            Policy will be deemed as your acceptance of those changes.
          </p>
          <p>
            <br />
            In case you disclose any Personal Data regarding any third person
            (e.g. your employee, management board member, co-worker, contracting
            party, etc.) to us, you are obliged to refer them to this Policy.
          </p>

          <h3>2. What information does the Company collect?</h3>
          <p>
            2.1. We collect Personal Data and Anonymous Data, as described
            below.
            <br />
            We may collect Personal Data from you, such as:
          </p>
          <ul>
            <li>First and last name</li>
            <li>E-mail and mailing addresses</li>
          </ul>
          <p>
            2.2. If you provide us feedback or contact us via e-mail, we will
            collect your name and e-mail address, as well as any other content
            included in the e-mail, in order to send you a reply. Some
            information is collected automatically by our servers:
          </p>
          <ul>
            <li>
              Our servers (which may be hosted by a third-party service
              provider) collect information from you, including your browser
              type, operating system, Intemet Protocol ("IP") address (a number
              that is automatically assigned to your computer when you use the
              Internet, which may vary from session to session), domain name,
              and/or a date/time stamp for your visit.
            </li>
            <li>
              As is true of most websites, we gather certain information
              automatically and store it in log files. This information includes
              IP addresses, browser type, Internet service provider ("ISP"),
              referring/exit pages, operating system, date/time stamp, and
              clickstream data.
            </li>
            <li>
              Like many online services, we use cookies to collect information.
              "Cookies" are small pieces of information that a website sends to
              your computer's hard drive while you are viewing the website.
            </li>
            <li>
              We retain information on your behalf, such as transactional data
              and other session data linked to your account.
            </li>
          </ul>
          <p>
            2.3. Some information is collected automatically by our servers:{" "}
          </p>
          <ul>
            <li>
              Our servers (which may be hosted by a third-party service
              provider) collect information from you, including your browser
              type, operating system, Intemet Protocol ("IP") address (a number
              that is automatically assigned to your computer when you use the
              Internet, which may vary from session to session), domain name,
              and/or a date/time stamp for your visit.{" "}
            </li>
            <li>
              As is true of most websites, we gather certain information
              automatically and store it in log files. This information includes
              IP addresses, browser type, Internet service provider ("ISP"),
              referring/exit pages, operating system, date/time stamp, and
              clickstream data.{" "}
            </li>
            <li>
              Like many online services, we use cookies to collect information.
              "Cookies" are small pieces of information that a website sends to
              your computer's hard drive while you are viewing the website.
            </li>
            <li>
              We retain information on your behalf, such as transactional data
              and other session data linked to your account.{" "}
            </li>
          </ul>
          <p>
            2.4. We do not collect any Special Categories of Personal Data about
            you (this includes details about your race or ethnicity, religious
            or philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and
            genetic and biometric data).{" "}
          </p>

          <h3>3. How does the Company use the information it collects?</h3>
          <p>
            3.1. In general, Personal Data you submit is necessary for
            contacting you in the context of the delivery of products or
            services and administering such products and services. Also, we use
            your Personal Data in the following ways:
          </p>
          <ul>
            <li>Facilitate the creation of and secure account on network</li>
            <li>
              Send a welcome e-mail to verify ownership of the e-mail address
              provided when account was created
            </li>
            <li>
              Send administrative e-mail notifications, such as account
              activity, transaction processing, security or support, and
              maintenance advisories
            </li>
            <li>
              Identify, prevent, and report potentially suspicious, fraudulent,
              or illegal activities
            </li>
            <li>Notify about important changes to Terms and Conditions</li>
            <li>
              Respond to inquiries related to employment opportunities or other
              requests
            </li>
            <li>Other: Updates, newsletters, news, products</li>
          </ul>
          <p>
            3.2. The Company does not sell user and/or Anonymous Data to any
            third party. Marketing and newsletter subscriptions.{" "}
          </p>

          <p>
            <br /> 3.3. We collect information about your behaviour within the
            application to offer you interesting information and offers
            regarding our services and products. This information includes your
            profile information and user status. This information is processed
            automatically and you as a user will notice it by seeing more
            relevant content when signed in. We do not share this information
            with third parties. The processing of such personal data is based on
            our legitimate interest to develop and promote our services and
            products. If you have subscribed to our mailing list, we will send
            you information on our new activities, events, and products. We can
            also send special offers directed to a special user group, users of
            a certain region, etc. Adding you to our mailing lists is based on
            your consent. You may also unsubscribe from the mailing list anytime
            by clicking the unsubscribe link at the bottom of each message.
            Customer support.{" "}
          </p>

          <p>
            <br /> 3.4. In case you need to contact our customer support, our
            support personnel will handle your contact information and the
            contents of your communication. Please do not share any sensitive
            data in your communication with our customer support. You can
            contact our customer support through various means. Depending on the
            means of communication you choose, we'll process the following
            contact details: - Your IP address (chat support) - Phone number
            (phone support, SMS support) - Email (email support) - User ID of an
            instant messaging app (QR code, support through an instant messaging
            app). We keep a record of the contents of the support tickets to
            make sure that you receive quality service and to develop our
            products and services. If you contact us by calling our support
            number, please note that we also record the phone calls for the same
            purposes. The processing of your personal data in connection with
            your communication with our customer service is based on the service
            agreement between you and the Company as well as our legitimate
            interest to follow up on the quality of our customer service, to
            verify the actions taken based on your request and to develop our
            service in the future.
          </p>

          <h3>4. How does the Company share my Personal Data?</h3>
          <p>
            It may be necessary to disclose your information to comply with any
            law, court orders, or government request, defend against claims,
            investigate or bring legal action against illegal or suspected
            illegal activities, enforce our Terms, or protect the rights,
            safety, and security of the Company, our users, or the public.
            <br />
            <br />
            Other than as stated in this Privacy Policy, the Company does not
            disclose any of your personal information to third parties unless
            required to do so by law enforcement, court order, or in compliance
            with legal reporting obligations.
            <br />
            <br />
            All third-party service providers that we share your data with shall
            be requested to only process your data in accordance with
            instructions from us and comply fully with this Privacy Policy, any
            applicable data protection laws and any other appropriate
            confidentiality and security measures. We may also share your
            personal data with competent authorities when this is necessary to
            comply with a legal obligation, court order or comparable official
            order issued by an authorized authority.
            <br />
            <br />
            We may share some or all of your Personal Data in connection with or
            during negotiation of any merger, financing, acquisition or
            dissolution transaction or proceeding involving sale, transfer,
            divestiture, or disclosure of all or a portion of our business or
            assets. In the event of an insolvency, bankruptcy, or receivership,
            Personal Data may also be transferred as a business asset.
            <br />
            <br />
            If another company acquires our company, business, or assets, that
            company will possess the Personal Data collected by us and will
            assume the rights and obligations regarding your Personal Data as
            described in this Privacy Policy.
            <br />
            <br />
            The Site may contain links to other third-party websites which are
            regulated by their own privacy policies. The Company is not
            responsible for the privacy policies of these third party websites
            even if they were accessed using the links from our site.
          </p>

          <h3>5. How long do we store your personal data?</h3>
          <p>
            We keep the data you have provided to us on your user account for
            the whole period of your customer relationship and as long as it is
            necessary with regard to the purposes of the processing described
            above.
          </p>

          <h3>
            6. What legal rights do you have in relation to your information?
          </h3>
          <p>
            6.1. Under certain circumstances, you have the following rights
            under data protection laws in relation to your personal data:
          </p>
          <ul>
            <li>Request access to your personal data.</li>
            <li>Request correction of your personal data.</li>
            <li>Request erasure of your personal data.</li>
            <li>Object to processing of your personal data.</li>
            <li>Request restriction of processing your personal data.</li>
            <li>Request transfer of your personal data.</li>
            <li>Right to withdraw consent.</li>
          </ul>
          <p>
            6.2. You also have the right to lodge a complaint with a Data
            Protection Authority ("DPA") if you think that your Personal Data is
            being processed incorrectly or your rights have been violated by the
            Company.
          </p>

          <h3>7. No fee is usually required</h3>
          <p>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights).
            <br />
            <br />
            <b>Request the transfer</b> of your personal data to you or to a
            third party. We will provide to you, or a third party you have
            chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
            <br />
            <br />
            <b>Withdraw consent at any time</b> where we are relying on consent
            to process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent. Please note that in
            certain circumstances it may be still lawful for us to continue
            processing your information even where you have withdrawn your
            consent if one of the other legal bases is applicable.
          </p>

          <h3>8. How can you send requests regarding your information?</h3>
          <p>
            You can make a request regarding your information by emailing{" "}
            <a href="mailto:info@esgate.es">info@esgate.es</a>.<br />
            <br />
            Unless you have made a corresponding request, we will retain your
            information for as long as your account has not been closed or as
            needed to provide you access to your account. <br />
            <br />
            If you wish to close your account, please contact support team via
            e-mail. We will retain and use your information as necessary to
            comply with our legal obligations, resolve disputes, and enforce our
            Terms.
          </p>

          <h3>9. Where do we store your Personal Data?</h3>
          <p>
            The information we collect is primarily stored on secure servers
            Germany. We will take all steps reasonably necessary to ensure that
            personal information is treated securely and in accordance with this
            Privacy Policy and that any third parties that we share your
            information with are bound to employ similar organizational and
            technical measures to ensure your information's security.
          </p>

          <h3>
            10. What security precautions does the Company take to protect me?
          </h3>
          <p>
            We take the protection of your personal information seriously. We
            use industry-standard data encryption technology and have
            implemented restrictions related to the storage of and the ability
            to access your personal information. However, despite all of our
            efforts, please note that no transmission over the Internet or
            method of electronic storage can not be guaranteed to be 100%
            secure.
          </p>

          <h3>11. Contact us</h3>
          <p>
            If you have any questions about our Privacy Policy as outlined
            above, please contact us at{" "}
            <a href="mailto:info@esgate.es">info@esgate.es</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
