import React, { useEffect } from "react";
import { waitForGtag } from "../utils/ga-utils";

const AboutUs = () => {
  useEffect(() => {
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
  });
  return (
    <div>
      <div className="policy">
        <div className="container">
          <div className="policy-heading">
            <h2>About Us</h2>
          </div>
          <div className="policy-content">
            <p>
              At ESGATE we are committed to pioneering a sustainable future by
              bridging the gap between non-governmental organizations (NGOs) and
              investors. Our mission is to support investors in reallocating
              capital to truly sustainable companies. We achieve this by making
              NGO data on environmental, social, and governance (ESG) matters
              accessible with just one click.
            </p>

            <h3>Our Vision</h3>
            <p>
              We envision a world where every investment decision is informed by
              reliable and transparent data on ESG practices. Our platform
              serves as a dynamic ecosystem where NGOs can share their
              invaluable data with investors, facilitating informed and
              impactful investment decisions that drive positive change.
            </p>

            <h3>Our Mission</h3>
            <p>
              Our mission is to empower investors by providing them with the
              tools they need to identify and invest in companies that are
              genuinely committed to sustainability. By consolidating critical
              ESG data from trusted NGOs, we ensure that investors have access
              to comprehensive and actionable insights.
            </p>

            <h3>Brand Essence</h3>
            <p>
              We are built on a foundation of integrity and transparency. Our
              commitment to these values ensures that all stakeholders have
              access to trustworthy information, enabling them to make decisions
              with confidence. By leveraging meticulous NGO data, our brand
              reinforces its dedication to authenticity and credibility,
              ultimately empowering our users to contribute positively to the
              world.
            </p>

            <h3>Our Core Values</h3>
            <p>
              <ul>
                <li>
                  Transparency: We believe in the power of openness and clarity,
                  ensuring that all data provided is clear and accessible.
                </li>
                <li>
                  Impact: Our focus is on making a significant positive impact
                  through every investment facilitated by our platform.
                </li>
                <li>
                  Sustainability: We are dedicated to promoting sustainable
                  finance by supporting companies that prioritize environmental,
                  social, and governance issues.
                </li>
                <li>
                  Independence: Maintaining our independence ensures that the
                  data we provide is unbiased and serves only the interests of
                  sustainable development.
                </li>
                <li>
                  Reliability: We strive to be a reliable partner to investors
                  and NGOs alike, providing consistent and independent data and
                  service.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
