import React, { useEffect } from "react";
import { waitForGtag } from "../utils/ga-utils";

const Faq = () => {
  useEffect(() => {
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
  });
  return (
    <div className="policy">
      <div className="container">
        <div className="policy-heading">
          <h2>FAQs</h2>
        </div>
        <div className="policy-content">
          <h3>What are ESGATE data sources ?</h3>
          <p>
            ESGATE data sources encompass a diverse array of datasets
            meticulously curated by non-governmental organizations (NGOs)
            worldwide. These NGOs engage in exhaustive data collection efforts
            aimed at scrutinizing corporate practices across various sectors.
            For instance, organizations like Trase specialize in investigating
            companies involved in the importation of goods from regions
            vulnerable to deforestation, while others such as Privacy Rights
            focus on monitoring global data breaches. The result of these
            endeavours is the creation of niche datasets that provide valuable
            insights into the environmental, social, and governance (ESG)
            performance of companies. By leveraging these comprehensive
            datasets, ESGATE offers users a robust foundation for informed
            decision-making and analysis in the realm of sustainable investing
            and corporate responsibility.
          </p>
          <h3>How does ESGATE match data to regulatory metrics?</h3>
          <p>
            ESGATE employs a sophisticated approach to match data to regulatory
            metrics, primarily leveraging Natural Language Processing (NLP)
            algorithms coupled with supplementary manual review activities.
            Through advanced NLP techniques, ESGATE sifts through vast amounts
            of data, extracting key information relevant to regulatory metrics.
            This automated process enables efficient and accurate identification
            of data points that align with specific regulatory requirements.
            Additionally, manual review activities provide valuable oversight
            and validation, ensuring the integrity and precision of the data
            matching process. By combining these methodologies, ESGATE delivers
            robust compliance solutions, enabling organizations to confidently
            navigate regulatory landscapes while maintaining adherence to
            established metrics and standards.
          </p>
          <h3>Who owns the data?</h3>
          <p>
            The data within ESGATE is owned by the non-governmental
            organizations (NGOs) responsible for its collection and curation.
            These NGOs dedicate significant resources to gather and organize
            data pertaining to environmental, social, and governance (ESG)
            performance from various sources. By virtue of their diligent
            efforts, NGOs retain ownership of the datasets they produce. ESGATE
            acts as a platform that aggregates and utilizes this data to provide
            valuable insights and analytics for informed decision-making in the
            realm of sustainable investing and corporate responsibility.
          </p>
          <h3>Who do I buy the data from?</h3>
          <p>
            In most cases, you don't need to purchase the data from any specific
            entity. The data available through ESGATE is often provided free of
            charge by non-governmental organizations (NGOs) dedicated to
            promoting transparency and accountability in corporate practices.
            These NGOs collect and curate the data as part of their mission to
            advance sustainability and social responsibility initiatives. As a
            result, the data is typically accessible to users without the need
            for purchasing it from a specific source. ESGATE serves as a
            platform to aggregate and present this valuable data, empowering
            users to make informed decisions and drive positive change in their
            investment and business practices.
          </p>
          <h3>How does the data licensing agreement work?</h3>
          <p>
            The data licensing agreement operates on the premise that many
            non-governmental organizations (NGOs) provide data free of charge
            for non-commercial use. Therefore, at ESGATE, we primarily charge
            for access to our advanced structuring system and matching
            algorithms, but not for the data itself.
          </p>
          <h3>How do NGOs benefit from belonging to the network?</h3>
          <p>
            NGOs affiliated with our network enjoy several benefits tailored to
            amplify their impact. One such advantage is access to a customized
            dashboard area, empowering them to assess their contributions and
            gauge their influence effectively. Additionally, we allocate
            revenues back to these NGOs, providing them with financial support
            to bolster their ongoing efforts. In instances where financial
            assistance is not required, surplus funds are directed towards
            charitable causes, further extending the positive impact of our
            network beyond the participating NGOs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
