import React, { useEffect, useRef } from "react";
import Hero from "../components/content/Hero";
// import Brand1 from "../assets/images/brand-1.png";
// import Brand2 from "../assets/images/brand-2.png";
// import Brand3 from "../assets/images/brand-3.png";
// import Brand4 from "../assets/images/brand-4.png";
import Features from "../assets/images/features.png";
import MarIcon from "../assets/images/mar-icon.png";
import R1 from "../assets/images/r1.png";
import R2 from "../assets/images/R2.png";
import R3 from "../assets/images/R3.png";
import R4 from "../assets/images/R4.png";
import R5 from "../assets/images/R5.png";
import R6 from "../assets/images/R6.png";
import S1 from "../assets/images/Avyse.png";
import S2 from "../assets/images/beraing point.png";
import S3 from "../assets/images/Caxia_bank.png";
import GDPR1 from "../assets/images/gdpr-1.png";
import GDPR2 from "../assets/images/gdpr-2.png";
import GDPR3 from "../assets/images/gdpr-3.png";
import RightArrow from "../assets/images/right-arrow.png";
import Addon1 from "../assets/images/addon-1.png";
import Addon2 from "../assets/images/addon-2.png";
import Addon3 from "../assets/images/addon-3.png";
import Quote from "../assets/images/quote.png";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { NavLink, useNavigate } from "react-router-dom";
import { waitForGtag } from "../utils/ga-utils";

const Home = () => {
  let Button = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });

    // On click take to particular section and scroll to top
    Button.current.addEventListener("click", function () {
      navigate(`/ContactUs`);
      window.scrollTo(0, 0);
    });
  });

  // Hero Text
  const HeroObject = {
    title: "Empowering <br/> <span>Sustainability,</span> <br/> Guiding Growth",
    shortDescription:
      "The gateway to alternative sustainability data for better financial decision making. Empowering investors with comprehensive metrics and insights.",
    buttonText: "Contact Us",
  };

  // Review Slider data
  const reviews = [
    {
      review:
        "We need access to ESG data in its original form, rather than relying solely on classifications.",
      name: "Jose Ignacio Goirigoizarri",
      designation: "Chairman at CaixaBank",
      dp: R1,
    },
    {
      review:
        "Alternative data is highly valued by investors, but it is important to know how to systematically collect data from NGOs.",
      name: "Ángel Agudo",
      designation: "VP Clarity AI",
      dp: R2,
    },
    {
      review:
        "Niche-specific data is powerful; data gaps are widespread in this industry.",
      name: "Rodrigo Tavares",
      designation: "CEO Granito Group",
      dp: R3,
    },
    {
      review:
        "More and more banks want to include ESG data in their risk scores.",
      name: "Felix Kreimer",
      designation: "Bearing Point",
      dp: R4,
    },
    {
      review:
        "Accurate ESG data is crucial to ensure compliance of sustainable investment strategies with increasing regulation.",
      name: "Ramón Varela",
      designation: "AVYSE Partners",
      dp: R5,
    },
    {
      review:
        "In the world of sustainable finance, ESG data is more than numbers or statistics; it is a tool for linking profit and purpose.",
      name: "Mansi Gupta",
      designation: "Founder Sustainable Sapiens",
      dp: R6,
    },
  ];

  // Case Study Slider data
  const caseStudies = [
    {
      title: "Human Rights Compliance - Privacy Protection",
      list: "<li>Regulation: SFDR: Share of investments in investee companies that have been involved in violations of the UNGC principles OECD Guidelines for Multinational Enterprises.</li><li>Source: Privacy Rights Clearinghouse (NGO)</li><li>Data: [Company: PayDay OK LLC| Type of Breach: HACK| Total Records Breached: 88]</li>",
      link: "/HumanRights",
    },
    {
      title: "Transparency in Renewable Energy Use",
      list: "<li>Regulation: SFDR - Share of energy from renewable sources. </li><li>Source: World Resource Institute (NGO)</li><li>Data: [Power Plant Name: Kajaki Hydroelectric Power Plant, Afghanistan| Power Source: Hydroelectric| Capacity (MW): 33.0]</li>",
      link: "/RenewableEnergy",
    },
    {
      title: "Supply Chain Monitoring",
      list: "<li>Regulation: MS - Supply Chain Monitoring.</li><li>Source: Trase (NGO)</li><li>Data: [Product: Brazilian Soybeans| Importer Group: BIO NUTRITION ANIMAL]</li>",
      link: "/SupplyChain",
    },
  ];

  // OwlCarousel options
  const caseStudySlider = {
    items: 2,
    autoplay: true,
    loop: true,
    nav: true,
    dots: false,
    margin: 40,
    stagePadding: 38,
    responsive: {
      1600: {
        stagePadding: 44,
      },
    },
  };
  const reviewsSlider = {
    autoplay: true,
    loop: true,
    nav: true,
    dots: false,
    margin: 0,
    responsive: {
      0: {
        stagePadding: 34,
        items: 1,
        nav: false,
      },
      600: {
        stagePadding: 50,
        items: 1,
      },
      991: {
        stagePadding: 50,
        items: 2,
      },
      1199: {
        stagePadding: 100,
        items: 2,
      },
    },
  };

  return (
    <>
      <Hero data={HeroObject} />
      <div className="bg-glow">
        <div className="bg-glow-inner">
          <svg
            height="1511"
            viewBox="0 0 1440 1511"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="desktop"
          >
            <g opacity="0.5" filter="url(#filter0_f_678_911)">
              <ellipse cx="714" cy="755.5" rx="523" ry="355.5" fill="#D9FFC0" />
            </g>
            <defs>
              <filter
                id="filter0_f_678_911"
                x="-209"
                y="0"
                width="1846"
                height="1511"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="200"
                  result="effect1_foregroundBlur_678_911"
                />
              </filter>
            </defs>
          </svg>
          <svg
            width="375"
            height="1847"
            viewBox="0 0 375 1847"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mobile"
          >
            <g opacity="0.5" filter="url(#filter0_f_1073_32170)">
              <ellipse
                cx="183"
                cy="923.156"
                rx="523"
                ry="355.5"
                transform="rotate(89.16 183 923.156)"
                fill="#D9FFC0"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1073_32170"
                x="-572.546"
                y="0.185364"
                width="1511.09"
                height="1845.94"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="200"
                  result="effect1_foregroundBlur_1073_32170"
                />
              </filter>
            </defs>
          </svg>
        </div>
        {/* <div className="brands">
          <div className="container">
            <h2>Leading organisations choose ESGATE</h2>
            <ul className="brands-group">
              <li>
                <img src={Brand1} alt="brand-logo" />
              </li>
              <li>
                <img src={Brand2} alt="brand-logo" />
              </li>
              <li>
                <img src={Brand3} alt="brand-logo" />
              </li>
              <li>
                <img src={Brand4} alt="brand-logo" />
              </li>
            </ul>
          </div>
        </div> */}
        <div className="reviews">
          <div className="container">
            <div className="reviews-heading">
              <h2>Listen to what others in the space say</h2>
            </div>
          </div>
          <div className="reviews-slider">
            <OwlCarousel options={reviewsSlider}>
              {reviews.map((review, index) => (
                <div key={index} className="carousel-item-inner">
                  <div>
                    <p>{review.review}</p>
                    <h3>
                      {review.name} <span>{review.designation}</span>
                    </h3>
                    <div className="img-parent">
                      <img src={review.dp} alt="" />
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
          <div className="container">
            <div className="seen">
              <p>As seen in</p>
              <ul>
                <li>
                  <img src={S1} alt="" />
                </li>
                <li>
                  <img src={S2} alt="" />
                </li>
                <li>
                  <img src={S3} alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="features">
          <div className="container">
            <div className="features-inner">
              <div className="row m-0">
                <div className="col-lg-6 col-12 p-0">
                  <div className="features-content">
                    <h2>Why our Data works!</h2>
                    <p>
                      We strive to ensure the accuracy of the data collected by
                      implementing a <br /> rigorous collection process.
                    </p>
                    <ul>
                      <li>
                        <h3>Strategic Collaboration:</h3>
                        <span>
                          We establish strategic partnerships with NGOs <br />{" "}
                          specialised in specific areas of sustainability.
                        </span>
                      </li>
                      <li>
                        <h3>Standardization with AI Technology:</h3>
                        <span>
                          We use artificial intelligence to standardise and{" "}
                          <br /> process the data, ensuring coherence and
                          quality.
                        </span>
                      </li>
                      <li>
                        <h3>Transformation for Investors:</h3>
                        <span>
                          We convert this data into accessible and meaningful{" "}
                          <br /> information for investors and other
                          participants <br /> in the financial market.
                        </span>
                      </li>
                      <li>
                        <h3>Compliance with Regulations:</h3>
                        <span>
                          We curate information to align with the disclosure{" "}
                          <br /> standards of the European Union and other
                          pertinent <br /> regulations, ensuring its utility for
                          compliance purposes.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-12 p-0">
                  <div className="features-thumb">
                    <img src={Features} alt="features" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addon-features">
          <div className="container">
            <div className="addon-features-inner">
              <div className="row m-0">
                <div className="col-lg-6 col-12 p-0">
                  <div className="addon-features-content">
                    <h2>
                      What makes <br /> us different!
                    </h2>
                    <p>
                      Committed to helping data make an impact, <br />{" "}
                      increasing the fairness of capital flows.
                    </p>
                    <button className="btn btn-primary" ref={Button}>
                      Get in Touch <img src={RightArrow} alt="right-arrow" />
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-12 p-0">
                  <div className="addon-features-list">
                    <ul>
                      <li>
                        <img src={Addon1} alt="" />
                        <div>
                          <h3>AI Tech-Excellence</h3>
                          <span>
                            We have a unique ad hoc software based on Artificial
                            Intelligence.
                          </span>
                        </div>
                      </li>
                      <li>
                        <img src={Addon2} alt="" />
                        <div>
                          <h3>Compliance & Transparency </h3>
                          <span>
                            We help comply with EU disclosure requirements to
                            ensure transparency in investments.
                          </span>
                        </div>
                      </li>
                      <li>
                        <img src={Addon3} alt="" />
                        <div>
                          <h3>Independence</h3>
                          <span>
                            We are committed to the independence of the data we
                            collect by ensuring the integrity of the
                            information.
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="decision" id="decision">
        <div className="container">
          <div className="decision-heading">
            <h2>
              The gateway to <span>alternative sustainability data</span> for
              better financial decision-making
            </h2>
            <div className="decision-slider-btn"></div>
          </div>
        </div>
        <div className="decision-slider">
          <OwlCarousel options={caseStudySlider}>
            {caseStudies.map((caseStudy, index) => (
              <div key={index} className="carousel-item-inner">
                <h3>{caseStudy.title}</h3>
                <ul dangerouslySetInnerHTML={{ __html: caseStudy.list }} />
                <NavLink className="btn btn-primary" to={caseStudy.link}>
                  Read Case Study <img src={RightArrow} alt="" />
                </NavLink>
              </div>
            ))}
          </OwlCarousel>
          <div className="mobile-cards">
            {caseStudies.map((caseStudy, index) => (
              <div key={index} className="carousel-item-inner">
                <h3>{caseStudy.title}</h3>
                <ul dangerouslySetInnerHTML={{ __html: caseStudy.list }} />
                <NavLink className="btn btn-primary" to={caseStudy.link}>
                  Read Case Study <img src={RightArrow} alt="" />
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="esg-grid">
        <div className="esg-grid-inner">
          <div className="esg-grid-main">
            <div className="one">
              <div className="layer layer-1"></div>
              <div className="layer layer-2"></div>
              <div className="layer layer-3"></div>
              <div className="one-inner">
                <p>
                  Structuring <br /> Data system
                </p>
              </div>
            </div>

            <div className="two">
              <h2>
                <img src={Quote} alt="" />
                ESG data is <br /> increasingly <br /> demanding{" "}
                <span>-Daniel Getler</span>
              </h2>
            </div>

            <div className="three">
              <div className="layer layer-1"></div>
              <div className="layer layer-2"></div>
              <div className="layer layer-3"></div>
              <div className="three-inner">
                <h2>200+</h2>
                <p>Regulatory metrics matched</p>
              </div>
            </div>
          </div>

          <div className="esg-grid-main">
            <div className="four">
              <div className="layer layer-1"></div>
              <div className="layer layer-2"></div>
              <div className="layer layer-3"></div>
              <div className="four-inner">
                <h2>21</h2>
                <p>
                  NGOs <br /> in discussion
                </p>
              </div>
            </div>

            <div className="five">
              <h2>100+</h2>
              <p>NGO Dataset</p>
            </div>

            <div className="six">
              <p>
                <img src={Quote} alt="" /> Need for NGO data <br /> about
                companies in bulk but there are no tools.{" "}
                <span>- Lena Berger</span>
              </p>
            </div>
          </div>

          <div className="esg-grid-main top">
            <div className="seven">
              <p>
                <img src={Quote} alt="" />
                My company would
                <br />
                like to create its own ratings but raw data is not easy to
                obtain in ESG matters. <span>- Cornelia Nissen</span>
              </p>
            </div>

            <div className="eight">
              <div className="layer layer-1"></div>
              <div className="layer layer-2"></div>
              <div className="layer layer-3"></div>
              <div className="eight-inner">
                <p>
                  SYSTEM <br /> to close <br /> data gaps
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ticker-wrap">
        <div class="ticker">
          <span class="item-collection-1">
            <p class="item">
              <img src={MarIcon} alt="" /> Empowering, Sustainability, Guiding
              Growth <img src={MarIcon} alt="" />
            </p>
            <p class="item">
              INDEPENT DATA, IMPACT DATA <img src={MarIcon} alt="" />
            </p>
            <p class="item">
              Empowering, Sustainability, Guiding Growth{" "}
              <img src={MarIcon} alt="" />
            </p>
            <p class="item">INDEPENT DATA, IMPACT DATA</p>
          </span>
          <span class="item-collection-2">
            <p class="item">
              <img src={MarIcon} alt="" /> Empowering, Sustainability, Guiding
              Growth <img src={MarIcon} alt="" />
            </p>
            <p class="item">
              INDEPENT DATA, IMPACT DATA <img src={MarIcon} alt="" />
            </p>
            <p class="item">
              Empowering, Sustainability, Guiding Growth{" "}
              <img src={MarIcon} alt="" />
            </p>
            <p class="item">INDEPENT DATA, IMPACT DATA</p>
          </span>
        </div>
      </div>
      <div className="faq" id="faq">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-6 col-12 p-0">
              <div className="faq-heading">
                <h2>FAQ</h2>
                <p>
                  Visit our help center to get in touch. <br /> We’re super
                  responsive.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 p-0">
              <div className="faq-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        How does ESGATE match data to regulatory metrics?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        ESGATE data sources encompass a diverse array of
                        datasets meticulously curated by non-governmental
                        organizations (NGOs) worldwide. These NGOs engage in
                        exhaustive data collection efforts aimed at scrutinizing
                        corporate practices across various sectors. For
                        instance, organizations like Trase specialize in
                        investigating companies involved in the importation of
                        goods from regions vulnerable to deforestation, while
                        others such as Privacy Rights focus on monitoring global
                        data breaches. The result of these endeavours is the
                        creation of niche datasets that provide valuable
                        insights into the environmental, social, and governance
                        (ESG) performance of companies. By leveraging these
                        comprehensive datasets, ESGATE offers users a robust
                        foundation for informed decision-making and analysis in
                        the realm of sustainable investing and corporate
                        responsibility.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How does ESGATE match data to regulatory metrics?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        ESGATE employs a sophisticated approach to match data to
                        regulatory metrics, primarily leveraging Natural
                        Language Processing (NLP) algorithms coupled with
                        supplementary manual review activities. Through advanced
                        NLP techniques, ESGATE sifts through vast amounts of
                        data, extracting key information relevant to regulatory
                        metrics. This automated process enables efficient and
                        accurate identification of data points that align with
                        specific regulatory requirements. Additionally, manual
                        review activities provide valuable oversight and
                        validation, ensuring the integrity and precision of the
                        data matching process. By combining these methodologies,
                        ESGATE delivers robust compliance solutions, enabling
                        organizations to confidently navigate regulatory
                        landscapes while maintaining adherence to established
                        metrics and standards.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Who owns the data?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        The data within ESGATE is owned by the non-governmental
                        organizations (NGOs) responsible for its collection and
                        curation. These NGOs dedicate significant resources to
                        gather and organize data pertaining to environmental,
                        social, and governance (ESG) performance from various
                        sources. By virtue of their diligent efforts, NGOs
                        retain ownership of the datasets they produce. ESGATE
                        acts as a platform that aggregates and utilizes this
                        data to provide valuable insights and analytics for
                        informed decision-making in the realm of sustainable
                        investing and corporate responsibility.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Who do I buy the data from?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        In most cases, you don't need to purchase the data from
                        any specific entity. The data available through ESGATE
                        is often provided free of charge by non-governmental
                        organizations (NGOs) dedicated to promoting transparency
                        and accountability in corporate practices. These NGOs
                        collect and curate the data as part of their mission to
                        advance sustainability and social responsibility
                        initiatives. As a result, the data is typically
                        accessible to users without the need for purchasing it
                        from a specific source. ESGATE serves as a platform to
                        aggregate and present this valuable data, empowering
                        users to make informed decisions and drive positive
                        change in their investment and business practices.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        How does the data licensing agreement work?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        The data licensing agreement operates on the premise
                        that many non-governmental organizations (NGOs) provide
                        data free of charge for non-commercial use. Therefore,
                        at ESGATE, we primarily charge for access to our
                        advanced structuring system and matching algorithms, but
                        not for the data itself.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        How do NGOs benefit from belonging to the network?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        NGOs affiliated with our network enjoy several benefits
                        tailored to amplify their impact. One such advantage is
                        access to a customized dashboard area, empowering them
                        to assess their contributions and gauge their influence
                        effectively. Additionally, we allocate revenues back to
                        these NGOs, providing them with financial support to
                        bolster their ongoing efforts. In instances where
                        financial assistance is not required, surplus funds are
                        directed towards charitable causes, further extending
                        the positive impact of our network beyond the
                        participating NGOs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gdpr">
        <div className="container">
          <div className="gdpr-inner">
            <div className="row m-0">
              <div className="col-lg-4 col-12 p-0">
                <h2>
                  European-based <br /> GDPR Compliant
                </h2>
              </div>
              <div className="col-lg-8 col-12 p-0">
                <ul>
                  <li>
                    <img src={GDPR1} alt="" />
                    <p>
                      Personal data is <br /> filtered out
                    </p>
                  </li>
                  <li>
                    <img src={GDPR2} alt="" />
                    <p>
                      Data is only stored <br /> under a purpose
                    </p>
                  </li>
                  <li>
                    <img src={GDPR3} alt="" />
                    <p>
                      Data is protected with <br /> stringent security measures
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
