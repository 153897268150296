import React, { useEffect } from "react";
import { waitForGtag } from "../utils/ga-utils";

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
  });
  return (
    <div className="policy">
      <div className="container">
        <div className="policy-heading">
          <h2>Terms of Service for ESGATE</h2>
        </div>
        <div className="policy-content">
          <h3>Acceptance of Terms</h3>
          <p>
            By accessing and using the website of ESGATE ("we", "us", or "our"),
            you agree to be bound by these Terms of Service ("Terms") and all
            applicable laws and regulations. If you do not agree with any of
            these terms, you are prohibited from using or accessing this site.
          </p>
          <h3>Use</h3>
          <p>
            Permission is granted to view the materials (information) on
            ESGATE's website for personal, non-commercial transitory viewing
            only. This is the grant of a license, not a transfer of title, and
            under this license, you may not:
            <ul>
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any information
                contained on ESGATE's website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
              <li>
                ESGATE reserves the right to take action if the terms outlined
                are not followed correctly.
              </li>
            </ul>
          </p>
          <h3>Disclaimer</h3>
          <p>
            The materials on ESGATE's website are provided on an 'as is' basis.
            ESGATE makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </p>
          <h3>Limitations</h3>
          <p>
            In no event shall ESGATE or its suppliers be liable for any damages
            (including, without limitation, damages for loss of data or profit,
            or due to business interruption) arising out of the use or inability
            to use the materials on ESGATE’s website, even if ESGATE or a ESGATE
            authorized representative has been notified orally or in writing of
            the possibility of such damage.
          </p>
          <h3>Accuracy of Materials</h3>
          <p>
            The materials appearing on ESGATE's website could include technical,
            typographical, or photographic errors. ESGATE does not warrant that
            any of the materials on its website are accurate, complete or
            current. ESGATE may make changes to the materials contained on its
            website at any time without notice. However, ESGATE does not make
            any commitment to update the materials.
          </p>
          <h3>Links</h3>
          <p>
            The inclusion of any link does not imply endorsement by ESGATE of
            the site. Use of any such linked website is at the user's own risk.
          </p>
          <h3>Modifications</h3>
          <p>
            ESGATE may revise these Terms of Service for its website at any time
            without notice. By using this website you are agreeing to be bound
            by the then current version of these Terms of Service.
          </p>
          <h3>Governing Law</h3>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of Spain and you irrevocably submit to the
            exclusive jurisdiction of the courts in that State or location.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about these Terms, please contact us at{" "}
            <a href="mailto:info@esgate.es">info@esgate.es</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
