import React, { useEffect } from "react";
import ContactusBg from "../assets/images/contact-bg.png";
// import HumanRights from "../assets/images/case-studies.jpg";
import HumanRightsThumb from "../assets/images/cs-privacy.jpg";
import { waitForGtag } from "../utils/ga-utils";

const HumanRights = () => {
  useEffect(() => {
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
    window.scrollTo(0, 0);
  });
  return (
    <div className="contact-us case-studies">
      <div className="contact-us-bg">
        <img src={ContactusBg} alt="" />
      </div>
      <div className="container">
        <div className="contact-us-heading">
          <h2>
            <span>CASE STUDY</span>
            Human Rights Compliance
            <br />
            Privacy Protection
          </h2>
        </div>
      </div>
      <div className="case-studies-thumb">
        <img src={HumanRightsThumb} alt="" />
      </div>
      <div className="case-studies-content">
        <h2>The Privacy Rights Clearinghouse </h2>
        <p>
          The Privacy Rights Clearinghouse is a nonprofit organization dedicated
          to enhancing access to information, fostering policy discussions, and
          advocating for meaningful rights to make data privacy a tangible and
          achievable goal for all individuals.
        </p>
        <p>
          The Data Breach Chronology is a specialized tool aimed at providing
          advocates, policymakers, journalists, and researchers with a
          comprehensive understanding of reported data breaches in the United
          States.
        </p>
        <p>
          The labels for breach type are as follows: The type of breach CARD
          refers to hacks of credit cards in grocery store termninals, bank
          terminals and so on. Therefore, it is related to one or more of the
          following subject/groups: Social and employee matters (SFDR), Affected
          communities (CSRD) and Consumer and end users (CSRD).
          <br />
          <br />
          The type of breach HACK, refers to outside party hacks, which in most
          cases involve the loss of sensible information from customers or
          employees. Therefore, it is related to one or more of the following
          subject/groups: Social and employee matters (SFDR), Human Rights
          (SFDR) and (MS), Own workforce (CSRD), Workers in the value chain
          (CSRD) and Consumers and end users (CSRD).
          <br />
          <br />
          The type of breach INSD, refers to data breaches caused by company
          internal personnel, contractors or clients. Therefore, it is related
          to one or more of the following subject/groups: Anti-corruption and
          anti-bribery (SFDR), Corruption (MS), Own workforce (CSRD), Workers in
          the value chain (CSRD), Consumers and end users (CSRD) or Business
          conduct (CSRD).
          <br />
          <br />
          The type of breach PHYS, refers to physical loss or stealing of paper
          documents. Therefore, it is related to Social and employee matters
          (SFDR), Anti-corruption and anti-bribery (SFDR), Corruption (MS), Own
          workforce (CSRD), Workers in the value chain (CSRD) and Business
          conduct (CSRD) since the custody of this information should have been
          on the workers themselves. The same subject/groups affect to PORT and
          STAT. The former entails losing discarding or being stolen a mobile
          device and the latter entails losing, discarding or being stolen a
          computer or server not designed for mobility.
          <br />
          <br />
          The type of breach DISC refers to sharing sensitive information
          publicly and unintentionally or intentionally. Therefore, it is
          related to one or more of the following subject/groups: Social and
          employee matters (SFDR), Anti-corruption and anti-bribery (SFDR),
          Corruption (MS), Business conduct (CSRD). The Corruption and
          Anti-corruption and anti-bribery subject/groups are included due to
          the relationship between employee intentional sensitive information
          disclosures and corruption cases.
        </p>

        <h2>Regulatory Relevance of the Data</h2>
        <p>
          The dataset provided by the Privacy Rights Clearinghouse, particularly
          the Data Breach Chronology, serves as a valuable resource for
          institutional investors aiming to assess various metrics related to
          Sustainable Finance and Responsible Investment (SFDR), Human Rights
          (MS), and Corporate Sustainability Reporting Directive (CSRD).
        </p>

        <h2>SFDR metrics</h2>
        <p>
          When considering SFDR metrics, the dataset facilitates the evaluation
          of investee companies' involvement in violations of principles
          outlined by the United Nations Global Compact (UNGC) and the
          Organisation for Economic Co-operation and Development (OECD)
          Guidelines for Multinational Enterprises. For instance, breaches
          categorized as HACK or INSD directly relate to principles against
          corruption, extortion, and bribery, as outlined in UNGC Principle 10.
          Additionally, breaches involving sensitive information disclosures
          (DISC) touch upon the aspect of respecting human rights, which aligns
          with the OECD Guidelines' emphasis on avoiding infringement on human
          rights.
        </p>

        <h2>MS metrics</h2>
        <p>
          Moreover, within the realm of MS metrics, the dataset aids in
          identifying instances where companies have breached consumer rights,
          particularly breaches categorized as HACK or DISC, which often involve
          the compromise of sensitive consumer data.
        </p>

        <h2>CSRD metrics</h2>
        <p>
          In terms of CSRD metrics, the dataset provides insights into the
          occurrence of severe human rights issues within a company's own
          workforce, as well as incidents of corruption or bribery. Breaches
          categorized as INSD directly relate to issues of corruption within a
          company, while breaches involving sensitive information disclosures
          (DISC) can shed light on incidents related to corruption or bribery,
          given the potential correlation between intentional information
          disclosures and corrupt practices.
        </p>
      </div>
    </div>
  );
};

export default HumanRights;
