import React, { useEffect } from "react";
import ContactusBg from "../assets/images/contact-bg.png";
import RenThumb from "../assets/images/cs-ren.jpg";
import { waitForGtag } from "../utils/ga-utils";

const RenewableEnergy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
  });

  return (
    <div className="contact-us case-studies">
      <div className="contact-us-bg">
        <img src={ContactusBg} alt="" />
      </div>
      <div className="container">
        <div className="contact-us-heading">
          <h2>
            <span>CASE STUDY</span>
            Transparency in Renewable <br /> Energy Use
          </h2>
        </div>
      </div>
      <div className="case-studies-thumb">
        <img src={RenThumb} alt="" />
      </div>
      <div className="case-studies-content">
        <p>
          The World Resources Institute (WRI) is dedicated to moving human
          society to live in ways that protect Earth's environment and its
          capacity to provide for the needs and aspirations of current and
          future generations. It emphasizes actionable research that can be used
          to influence policies and practices globally.
        </p>
        <p>
          The Global Power Plant Database compiles a comprehensive, open-source
          database of power plants worldwide, covering approximately 35,000
          power plants across 167 countries. It includes information on plant
          capacity, generation, ownership, and the type of fuels used, ranging
          from thermal to renewable energy sources.
        </p>
        <h2>Regulatory Relevance of the Data</h2>
        <p>
          The data serves as a foundational tool for stakeholders needing to
          comply with various environmental and sustainability regulations,
          ensuring informed decision-making regarding energy production and its
          environmental impacts.
        </p>
        <h2>Specific Regulations Covered</h2>
        <p>
          <h2>Sustainable Finance Disclosure Regulation (SFDR):</h2>
          <br />
          The database supports SFDR by providing transparency in energy
          investments and their environmental impacts, helping investors
          identify sustainable opportunities and risks.
        </p>
        <p>
          <h2>Minimum Safeguards (MS):</h2>
          <br />
          Data from the database can aid in MS strategies by providing detailed
          insights into power plant emissions and operational statuses, crucial
          for carbon pricing and emissions trading schemes.
        </p>
        <p>
          <h2>Corporate Sustainability Reporting Directive (CSRD):</h2>
          <br />
          Companies can use this comprehensive data to enhance the accuracy of
          their sustainability reports concerning energy production and
          management, complying with CSRD requirements.
        </p>
        <p>
          <h2>EU Taxonomy:</h2>
          <br />
          The database aligns with the EU Taxonomy by detailing the types of
          energy sources and their emissions, helping classify sustainable
          activities and investments in the energy sector.
        </p>
      </div>
    </div>
  );
};

export default RenewableEnergy;
