import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/svgs/esg_logo.svg";
import Hamburger from "../../assets/images/hamburger-icon.png";
import Close from "../../assets/images/close-icon.png";
import CookiePopup from "../content/CookiePopup";
import RightArrow from "../../assets/images/right-arrow.png";

const Header = () => {
  let Navbar = useRef();
  const hamburgerClick = (e) => {
    Navbar.current.classList.toggle("nav-slide");
    if (Navbar.current.classList.contains(`nav-slide`)) {
      e.target.setAttribute(`src`, `${Close}`);
    } else {
      e.target.setAttribute(`src`, `${Hamburger}`);
    }
  };

  const closeNavbar = () => {
    if (Navbar.current.classList.contains("nav-slide")) {
      Navbar.current.classList.remove("nav-slide");
      document
        .querySelector(`.navbar-toggle img`)
        .setAttribute(`src`, `${Hamburger}`);
    }
  };

  useEffect(() => {
    let navLogo = document.querySelector(`.navbar-brand`);
    let navLinks = document.querySelectorAll(`.navbar-nav li a`);
    navLinks.forEach(function (item) {
      item.addEventListener(`click`, function () {
        closeNavbar();
      });
    });
    navLogo.addEventListener(`click`, function () {
      closeNavbar();
    });
    let HeaderLinks = document.querySelectorAll(".header a");
    HeaderLinks.forEach(function (item) {
      item.addEventListener("click", function () {
        window.scrollTo(0, 0);
      });
    });
  }, []);

  return (
    <>
      <header className="header">
        <div className="navbar-main">
          <nav className="navbar">
            <div className="navbar-brand">
              <NavLink to="/">
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            <button className="navbar-toggle" onClick={hamburgerClick}>
              <img src={Hamburger} alt="hamburger-icon" />
            </button>
            <div className="nav-collapse" ref={Navbar}>
              <ul className="navbar-nav navbar-center">
                <li className="nav-item">
                  <NavLink to="/AboutUs">About us</NavLink>
                </li>
                <li className="nav-item dropdown">
                  <button
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Case Studies
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <NavLink className="dropdown-item" to="/HumanRights">
                        Human Rights
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/SupplyChain">
                        Supply Chain
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/RenewableEnergy">
                        Renewable Energy
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink to="/Faq">FAQs</NavLink>
                </li>
              </ul>
              <ul className="navbar-nav navbar-right">
                <li className="nav-item">
                  <NavLink to="/ContactUs">
                    Get in touch <img src={RightArrow} alt="right-icon" />
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <CookiePopup />
    </>
  );
};

export default Header;
