export const waitForGtag = (callback, timeout = 10000) => {
  const interval = 50; // check every 50ms
  let elapsed = 0;
  const checkGtag = () => {
    if (typeof window.gtag === "function") {
      callback();
    } else if (elapsed < timeout) {
      elapsed += interval;
      setTimeout(checkGtag, interval);
    } else {
      console.error("gtag.js not loaded");
    }
  };

  checkGtag();
};
