import React, { useEffect } from "react";
import ContactusBg from "../assets/images/contact-bg.png";
import SupplyChainThumb from "../assets/images/cs-supply.jpg";
import { waitForGtag } from "../utils/ga-utils";

const SupplyChain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    waitForGtag(() => {
      if (localStorage.getItem("cookieConsentAccepted")) {
        window.gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      }
    });
  });

  return (
    <div className="contact-us case-studies">
      <div className="contact-us-bg">
        <img src={ContactusBg} alt="" />
      </div>
      <div className="container">
        <div className="contact-us-heading">
          <h2>
            <span>CASE STUDY</span>
            Supply Chain <br /> Monitoring
          </h2>
        </div>
      </div>
      <div className="case-studies-thumb">
        <img src={SupplyChainThumb} alt="" />
      </div>
      <div className="case-studies-content">
        <h2>Trase</h2>
        <p>
          Trase is a research initiative that provides data-driven insights to
          enhance the sustainability and equity of agricultural supply chains.
          It aims to empower businesses, governments, and civil societies to
          eliminate deforestation and transition towards more sustainable
          practices.
        </p>
        <p>
          Trase offers open-access data that integrates diverse datasets on
          commodity production, trade, and environmental impacts such as
          deforestation. This data helps stakeholders understand and manage
          sustainability risks associated with commodity supply chains.
        </p>
        <h2>Regulatory Relevance of the Data</h2>
        <p>
          The data provided by Trase is crucial for adhering to international
          sustainability regulations and standards, helping stakeholders to:
          <br />
          <br />
          <ul>
            <li>Track and manage deforestation risks in supply chains.</li>
            <li>
              Comply with environmental impact reporting and reduction
              commitments.
            </li>
            <li>
              Support government and multi-stakeholder initiatives on
              sustainability.
            </li>
          </ul>
        </p>
        <h2>SFDR metrics</h2>
        <p>
          Trase’s data aids financial sectors in assessing and acting on their
          exposure to deforestation risks in their portfolios, facilitating
          compliance with SFDR requirements on sustainability risk disclosure
          and management.
        </p>
        <h2>CSRD metrics</h2>
        <p>
          The detailed supply chain and environmental impact data provided by
          Trase helps companies report on sustainability practices and impacts
          accurately, aligning with CSRD mandates for transparency and
          disclosure of sustainability information.
        </p>
      </div>
    </div>
  );
};

export default SupplyChain;
